/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import {Media} from "../media/media"
import { Link } from 'gatsby'


import LogoGordo from "../images/gordo-logo.png"
import Header from "./header"
import "./layout.css"

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    font-family: 'roboto', sans-serif;
  } 

  ::placeholder {
    color: grey;
    opacity: 1;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
     font-family: 'Bebas Neue', cursive;
  }

  p{
    font-weight:300;
    font-size:1.4em;
    line-height:1.5em;
  }


  h1,
  h2,
  h3,
  h4,
  h5, 
  h6, 
  p, 
  li, 
  ul, 
  a{
    color: black;
  }

  img{
    max-width:100%;
  }
  

  header{
    z-index:9999;
    margin-top:20px;
    position: sticky;
    top: -150px;
    display: block;
  }


    nav{
    text-align:center;
    background-color: white;

    ul{
      margin: 0;
      display:flex;
      flex-direction: row;
      justify-content: space-around;
      align-items:center;
    }
    
    li{
      list-style: none;
      font-family: 'Bebas neue', sans-serif;
      color: #005B25;
      font-size:1.3em;
      cursor: pointer;
      a{
        font-family: inherit;
        color: inherit;
        text-decoration: none;
      }
    }
  }

  main{
    margin-top: 50px;
    section{
      text-align:center;
    }
  }

  body {
    margin: 0;
    padding: 0;
  }
  
  footer{
    img{
      width: 80px;
      vertical-align: middle;
    }
    ul{
      margin: 4em 1em 0.1em 1em;
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; 
      li{
       &:first-child{
        font-family: 'Afta Serif';
        text-transform: uppercase;
      }
    }
    }
  }

  /*other -styles*/
  
  .kmf-button{
      font-family: 'Bebas neue', sans-serif;
      color: white;
      background: #005B25;
      border: none;
      padding: 0.5em 1em;
      a{
        vertical-align:middle;
        display:inline-block;
        font-size: inherit;
        font-family: inherit;
        color:inherit;
      }
      img{
          vertical-align:middle;
          margin:0;
          margin-left: 0.5em;
          height:1.1em;
      }
    }

  article{
    dl{
      dt{
          color:black;
          font-size:1.4em;
          line-height:1.5em;
          margin-bottom:1em;
      }
      dd{
        p{
          font-weight:700;
        }
        p,li{
          font-size: 1em;
          line-height:1.3em;
        }
        li::marker{
            font-weight: 700;
        }
      }
    }
  }
  
  #rcc-confirm-button{
    background-color:red 
  }


    ${Media.sm`
      body{
        font-size:12px;
        padding: 0 1em;
      }

      header{
        nav{
          ul{
            flex-direction: row;
            flex-wrap: wrap;
            li{
              flex-basis:20%;
            }
          }
        }
      }
      #contact{
        width:80%;
      }
    `}

`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      < GlobalStyle />
        <main> 
          {children} 
        </main>
        <footer>
          <ul>
            <li>© KOMFORTUM {new Date().getFullYear()}</li>
            <li><Link to="/privacy-policy">Polityka Prywatności</Link></li>
            <li>Realizacja:<a href="https://agencjagordo.pl"><img src={LogoGordo} alt="gordo logo" /></a></li>
          </ul>
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
