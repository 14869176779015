import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LogoKomf from "../images/komfortum-logo.png"
import Arrow from "../images/arrow.svg"

const handleClick = (e) =>{
  if (document.querySelector('#mainpage')) {
    document.querySelector(e.target.dataset.id).scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }else{
    window.history.back();
  }
}

const Header = ({ siteTitle }) => (
  <header>
        <nav>
          <img style={{width:'150px'}} src={LogoKomf} alt='konfortum logo' />
          <ul>
            <li onClick={handleClick} data-id="#about" >o nas</li>
            <li onClick={handleClick} data-id="#services" >nasze usługi</li>
            <li onClick={handleClick} data-id="#cowork" >współpraca</li>
            <li onClick={handleClick} data-id="#contact" >kontakt</li>
            <Link style={{textDecoration:'none'}} to="https://www.provirtus.pl/ikm/login.aspx"><li className="kmf-button">strefa mieszkańca<img src={Arrow} alt="strzałka" /></li></Link>
          </ul>
        </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
